/** @jsx jsx */
import { jsx } from "theme-ui"
import { Helmet } from "react-helmet"

export default function PageMetadata() {

  const title = 'K U L I S \u2013 Design Studio'
  const description = 'I am a Polish designer living in Warsaw. I want to create honest and useful spaces &amp; things that are interacting with people.'
  const ogImg = 'https://karolinakulis.com/intro.jpg'
  const ogUrl = 'https://karolinakulis.com/'
  return (
    <Helmet>
      <link rel='preload' href='/fonts/fonts.css' />
      <link rel='preload' href='/fonts/rewir.woff' as='font' type='font/woff' crossorigin />
      {title && <title>{title}</title>}
      {title && <meta property='og:title' content={title} />}
      {description && <meta name='description' content={description} />}
      {description && <meta property='og:description' content={description} />}
      <meta property="og:image:secure" content={ogImg} />
      <meta property="og:image" content={ogImg} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content="text/html" />
    </Helmet>
  )
}
