export default {
  firstColor: '#f16557',
  backgroundColor: '#f2f2f2',
  scrollColor: '#dadbd7',
  stripeColor: '#dadbd7',
  buttonColor: '#f16557',
  menuColor: '#32436e',
  textColor: '#1D1D1B',
  pageBackground: '#f2f2f2',
  mediaMinWidth: 768
}
